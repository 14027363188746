import React from 'react';
import { Container } from 'Common';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { graphql, StaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { BuildWith } from '../../common/BuildWith';
import {Button} from "../../common/Button";
import iosAppStore from "../../../assets/app-store.svg";

export const MadeMyDay = () => {
  return (
    <Wrapper id="made-my-day">
      <SkillsWrapper as={Container}>
        <Details>
          <h1>
            Made my day
          </h1>
          <p>
            Minimalistic journal app for macOS. <br /><br />
            It does not require a monthly subscription and has a standard and easy convertible file format (Markdown)
          </p>
          <a
              href="http://developapa.com/made-my-day/"
          >
            <Button>Learn more</Button>
          </a>
          <a href="https://apps.apple.com/us/app/made-my-day/id1481700999?l=de&ls=1&mt=12">
            <img
                alt="Get it on app store"
                style={{ marginBottom: '-15px', marginLeft: '16px' }}
                src={iosAppStore}
            />
          </a>
          <BuildWith> Build with: React</BuildWith>
        </Details>
        <Thumbnail>
          <StaticQuery
            query={graphql`
              query MadeMyDayQuery {
                logo: file(absolutePath: { regex: "/made-my-day.png/" }) {
                  childImageSharp {
                    fluid(maxWidth: 450) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
            render={(data) => {
              return (
                <Image
                  fluid={data.logo.childImageSharp.fluid}
                  alt="Made my day logo"
                  style={{}}
                  imgStyle={{}}
                />
              );
            }}
          />
        </Thumbnail>
      </SkillsWrapper>
    </Wrapper>
  );
};
