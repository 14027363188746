import React from 'react';
import { Layout, SEO } from 'Common';
import {
    Intro,
    Skills,
    Confin,
    Developapa,
    Zatacka,
    MadeMyDay,
    Contact,
} from '../components/landing';

export default () => (
  <Layout>
    <SEO />
    <Intro />
    <Skills />
    <Developapa />
    <Confin />
    <MadeMyDay />
    <Zatacka />
    <Contact />
  </Layout>
);
