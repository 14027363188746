import React from 'react';
import { Container } from 'Common';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { graphql, StaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import iosAppStore from '../../../assets/app-store.svg';
import { BuildWith } from '../../common/BuildWith';
import githubIcon from '../../../../static/icons/github.svg';
import { Links } from '../../theme/Footer/styles';

export const Zatacka = () => {
  return (
    <Wrapper id="zatacka">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <StaticQuery
              query={graphql`
              query ZatackaQuery {
                logo: file(absolutePath: { regex: "/zatacka.png/" }) {
                  childImageSharp {
                    fluid(maxWidth: 450) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
              render={(data) => {
                return (
                    <Image
                        fluid={data.logo.childImageSharp.fluid}
                        alt="zatacka logo"
                        style={{}}
                        imgStyle={{}}
                    />
                );
              }}
          />
        </Thumbnail>
        <Details>
          <h1>
            <Links>
              Zatacka
              <a
                href="https://github.com/ngehlert/kurve"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="github link"
              >
                <img
                  width="28"
                  src={githubIcon}
                  style={{
                    marginLeft: '6px',
                  }}
                  alt="github icon"
                />
              </a>
            </Links>
          </h1>
          <p>
            Remake of the classic DOS game Zatacka ('Achtung, die Kurve!')
            <br />
            Play on different platforms
          </p>
          <ul>
            <li>
              <div>Online</div>
              <a href="https://kurve.ngehlert.de/">Click here</a>
            </li>
            <li>
              <div>macOS</div>
              <a href="https://apps.apple.com/de/app/achtung-die-kurve/id1383458252?mt=12">
                <img
                  alt="Get it on app store"
                  style={{ marginBottom: '-15px' }}
                  src={iosAppStore}
                />
              </a>
            </li>
            <li>
              <div>iOS</div>
              <a href="https://apps.apple.com/ca/app/zatacka-curve/id1453842976">
                <img
                  alt="Get it on app store"
                  style={{ marginBottom: '-15px' }}
                  src={iosAppStore}
                />
              </a>
            </li>
          </ul>
          <BuildWith> Build with: JavaScript</BuildWith>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
