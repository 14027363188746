import React from 'react';
import { Header } from 'Theme';
import { Container } from 'Common';
import dev from 'Static/illustrations/dev.svg';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';
const config = require('./../../../../data/config');

export const Intro = () => (
  <Wrapper>
    <Header />
    <IntroWrapper as={Container}>
      <Details>
        <h1>I'm a Developer</h1>
        <h4>{config.defaultDescription}</h4>
      </Details>
      <Thumbnail>
        <img src={dev} alt="software developer" />
      </Thumbnail>
    </IntroWrapper>
  </Wrapper>
);
