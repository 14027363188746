import React from 'react';
import { Container } from 'Common';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { graphql, StaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import iosAppStore from '../../../assets/app-store.svg';
import googleAppStore from '../../../assets/google-play-badge.png';
import { BuildWith } from '../../common/BuildWith';

export const Confin = () => {
  return (
    <Wrapper id="confin">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <StaticQuery
            query={graphql`
              query ConfinQuery {
                logo: file(absolutePath: { regex: "/confin.png/" }) {
                  childImageSharp {
                    fluid(maxWidth: 450) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
            render={(data) => {
              return (
                <Image
                  fluid={data.logo.childImageSharp.fluid}
                  alt="Confin logo"
                  style={{}}
                  imgStyle={{}}
                />
              );
            }}
          />
        </Thumbnail>
        <Details>
          <h1>Confin</h1>
          <p>
            A mobile app to help you control your finances <br />
            <br />
            Confin helps you to keep track of your own finances
            <br />
            On the basis of own created categories individual statistics are
            generated
          </p>
          <a href="https://itunes.apple.com/us/app/confin/id1292935058?l=de&ls=1&mt=8">
            <img
              alt="Get it on app store"
              style={{ marginBottom: 0 }}
              src={iosAppStore}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.ionicframework.finance2897887">
            <img
              alt="Get it on Google Play"
              style={{
                height: '60px',
                marginBottom: '-10px',
                marginLeft: '8px',
              }}
              src={googleAppStore}
            />
          </a>
          {/*
          <Button as={AnchorLink} href="#contact">
            Hire me
          </Button>
           */}
          <BuildWith> Build with: Angular</BuildWith>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
