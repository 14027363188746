import React from 'react';
import { Container } from 'Common';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';
import { graphql, StaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { Button } from '../../common/Button';
import githubIcon from '../../../../static/icons/github.svg';
import { BuildWith } from '../../common/BuildWith';
import { Links } from '../../theme/Footer/styles';

export const Developapa = () => {
  return (
    <Wrapper id="developapa">
      <SkillsWrapper as={Container}>
        <Details>
          <h1>
            <Links>
              Developapa
              <a
                href="https://github.com/ngehlert/developapa"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="github link"
              >
                <img
                  width="28"
                  src={githubIcon}
                  style={{
                    marginLeft: '6px',
                  }}
                  alt="github icon"
                />
              </a>
            </Links>
          </h1>
          <p>
            My personal blog with posts about various developer topics and my
            life as a parent
          </p>
          <a
            href="https://developapa.com"
          >
            <Button>Learn more</Button>
          </a>
          <BuildWith> Build with: Gatsby / React</BuildWith>
        </Details>
        <Thumbnail>
          <StaticQuery
            query={graphql`
              query DevelopapaQuery {
                logo: file(absolutePath: { regex: "/developapa.png/" }) {
                  childImageSharp {
                    fluid(maxWidth: 400, maxHeight: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
            render={(data) => {
              return (
                <Image
                  fluid={data.logo.childImageSharp.fluid}
                  alt="Developapa logo"
                  style={{ maxWidth: '400px' }}
                  imgStyle={{}}
                />
              );
            }}
          />
        </Thumbnail>
      </SkillsWrapper>
    </Wrapper>
  );
};
