import React from 'react';
import { Container } from 'Common';
import dev from 'Static/illustrations/skills.svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => (
  <Wrapper id="about">
    <SkillsWrapper as={Container}>
      <Thumbnail>
        <img src={dev} alt="I’m John and I’m a Backend & Devops engineer!" />
      </Thumbnail>
      <Details>
        <p>
          Currently I work as a web developer for the german company ActiveAgent
          AG.
        </p>
        <p>Check out some of my spare time projects</p>
        {/*
        <Button as={AnchorLink} href="#contact">
          Hire me
        </Button>
         */}
      </Details>
    </SkillsWrapper>
  </Wrapper>
);
